body {
  margin: 100px 0 0 0;
  padding: 0;
  width: 100%;
  font-family: "Montserrat", sans-serif;
  overflow: hidden scroll;
}

.services {
  z-index: 2;
}

.emergencies {
  text-align: center;
  width: 80%;
  margin-left: 5%;
  padding-left: 5%;
  padding-right: 5%;
  padding-bottom: 30px;
  background-color: rgba(255, 255, 255, 0.8);
}

textarea::placeholder {
  font-family: "Work Sans", sans-serif !important;
  padding: 10px !important;
  color: #333;
}

input::placeholder {
  font-family: "Work Sans", sans-serif !important;
  color: #333;
}



.services:after {
  width: 100% !important;
  z-index: 1;
  height: 110px;
  top: 0;
  bottom: 0px;
  right: 0px;
  content: "";
  margin-top: -110px;
  background-color: #f3f6fa;
  transform-origin: top left;
  transform: skewY(4deg);
  z-index: -11 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.home {
  background: #ffffff url("./assets/Home/HomeImage.png") no-repeat center center;
  background-size: cover;
}

.topnav {
  background-color: "white" !important;
}

.topnav a {
  float: left;
  display: block;
  text-align: center;
  padding: 14px 1px;
  text-decoration: none;
  font-size: 17px;
}

.topnav .icon {
  display: none;
}

.hidden {
  display: none;
}

@media screen and (max-width: 1300px) {
  body {
    margin-top: 0 !important;
    padding: 0;
  }

  .doctor-card {
    margin: 0 10px !important;
  }

  .hideable {
    display: none;
  }

  .hidden {
    display: block;
  }

  .public-transportation {
    margin: -50px 20px 0 15px !important;
    z-index: 999 !important;
  }

  .access .logo-container {
    margin-left: 30px;
  }

  .contacts {
    display: block !important;
    padding-left: 30px;
  }

  .opening-hours h3,
  .opening-hours h6 {
    text-align: center !important;
  }

  .more-info {
    margin-top: 30px !important;
  }
}

@media screen and (min-width: 1301px) {

  .access,
  .contacts {
    margin-left: 50px;
  }

  .public-transportation {
    margin-top: -100px !important;
    margin-left: 65px !important;
    z-index: 999 !important;
  }

  .public-transportation p {
    padding-right: 40px !important;
  }

  .team {
    margin-top: 50px;
    padding-bottom: 50px;
  }

  .doctor-card {
    margin: 0 10px !important;
  }
}

@media screen and (max-width: 539px) {
  .emergency-card {
    min-width: 100% !important;
    padding: 20px !important;
    margin-left: -20px;
  }

  .schedule-appointment h3 {
    margin-top: 100px;
  }

  .service-card>div {
    width: 320px !important;
  }
}

@media screen and (max-width: 960px) {
  .home-mobile {
    display: block;
  }

  .home-mobile .background {
    background: #ffffff url("./assets/Home/HomeMobile.png");
    background-size: cover;
  }

  .home {
    display: none !important;
  }

  .doctor-card {
    margin: 20px 0 !important;
  }
}

@media screen and (min-width: 961px) {
  .home-mobile {
    display: none !important;
  }

  .home {
    display: flex !important;
  }
}

.react-calendar__navigation button,
.react-calendar__month-view__weekdays {
  font-family: "Source Sans Pro", sans-serif;
  color: #808faa;
  font-weight: bold !important;
  font-size: 1em !important;
  text-decoration: none !important;
}

.react-calendar__month-view__days__day {
  font-family: "Work Sans", sans-serif !important;
  color: #808faa !important;
  font-size: 0.9em !important;
}

.react-calendar__tile--active {
  background: rgba(0, 0, 0, 0.1) !important;
  border-radius: 100%;
}

td .ant-col .ant-radio-group-default {
  display: none !important;
}

#RBS-Scheduler-root thead tr td .ant-row-flex {
  text-align: center !important;
}

.rat-AvailableTimes_calendarSelector {
  display: none;
}

.react-calendar {
  border: none !important;
}

.scheduling-table-wrapper {
  height: 730px !important;
  overflow: scroll scroll;
  margin-bottom: 50px;
}

.scheduling-table thead tr th {
  background-color: #eaf0f4;
}

.scheduling-table th {
  color: #224e9d;
  font-weight: bold;
  text-align: center !important;
}

.scheduling-table td {
  cursor: pointer;
  min-width: 100px;
  border-left: 1px solid rgba(224, 224, 224, 1);
  border-right: 1px solid rgba(224, 224, 224, 1);
}

.scheduling-table td.free:hover {
  background: rgba(199, 255, 222, 0.5) !important;
}

.scheduling-table td.free {
  background: rgba(144, 238, 144, 0.8) !important;
}

.scheduling-table td.unavailable {
  background: #f5f6fa !important;
}

.scheduling-table td.taken {
  background: rgba(255, 0, 7, 0.6) !important;
}

.scheduling-table td.custom {
  background: yellowgreen !important;
}

.scheduling-table td.special {
  background: aquamarine !important;
}

.scheduling-table td.passed {
  background: orange !important;
}

div.gm-inset .gm-inset-map,
div.gm-inset .gm-inset-map-small,
div.gm-inset .gm-inset-light {
  display: none !important;
}

div.contact-us>div:nth-child(3)>div:nth-child(1)>div>div>div:nth-child(3) {
  display: none;
}

.gmnoprint,
.gmnoscreen,
.gmnoprint,
.gm-style-cc,
.gm-control-active,
.gm-fullscreen-control {
  display: none;
}